<template>
    <div class="background">
        <div class="list-container">
            <div class="header-container">
                <div class="header-top">
                    <div class="header">
                        {{ $t('couponHeader.header') }}
                    </div>
                    <div class="valid-until">
                        {{ $t('couponHeader.validUntil') }} {{ dateTimeUtils.dateFormatter(offer.endDate) }}
                    </div>
                </div>

            </div>
            <div class="instruction-outer-container my-3">
                <span class="instruction-container">
                    <div class="step-number">1.</div>
                    <div class="bold-instruction">{{ $t('couponHeader.firstInstruction')[0] }}</div>

                </span>
                <span class="instruction-container">
                    <div class="step-number">2.</div>
                    <div class="bold-instruction">{{ $t('couponHeader.secondInstruction')[0] }}</div>

                </span>
                <span class="instruction-container">
                    <div class="step-number">3.</div>
                    <div class="bold-instruction">{{ $t('couponHeader.thirdInstruction')[0] }}</div>

                </span>
            </div>
        </div>
        <div class="coupon-selection-section">
            <div class="coupons-number">
                {{ activatedCoupons }} {{ $t('couponHeader.from') }} {{ offer.coupons.length }} {{ $t('couponHeader.active') }}
            </div>
            <button
                class="select-all-btn"
                :class="{disabled: isAllEnabled}"
                type="button"
                @click="activateAllCoupons"
            >
                {{ $t('webCoupons.selectAll') }}
            </button>
        </div>
        <div class="coupons-container">
            <div
                v-for="coupon in coupons"
                :key="coupon.id"
            >
                <div class="coupon-container">
                    <div class="image-redeemed">
                        <img
                            class="coupon"
                            :src="coupon.colorPath"
                            :class="[{'opacity-changer': coupon.cashRedemptionDate},{ 'activated': (coupon.redemptionWay || coupon.isFlipped) && !coupon.cashRedemptionDate}]"
                            @click="selectCoupon(coupon)"
                        >
                        <div v-if="coupon.cashRedemptionDate && coupon.cashRedemptionKiosk" class="coupon-redeemed">
                            <img v-if="coupon.cashRedemptionKiosk && coupon.cashRedemptionKiosk.storeCode === 686" src="@/assets/eshop.png" alt="">
                            <img v-else src="@/assets/shop.png" alt="" draggable="false">
                            <p v-if="!coupon.cashRedemptionKiosk" class="header-shop-text">{{ $t('webCoupons.fanchise') }}</p>
                            <p v-if="coupon.cashRedemptionKiosk.storeCode !== 686" class="header-shop-text">{{ $t('webCoupons.shop') }}</p>
                            <p v-else class="header-shop-text">{{ $t('webCoupons.eshop') }}</p>
                            <div v-if="coupon.cashRedemptionKiosk.storeCode === 686" class="simple-shop-text">
                                <div>{{ $t('webCoupons.eshopText')[0] }}</div>
                                <div>{{ $t('webCoupons.eshopText')[1] }}</div>
                            </div>
                            <div v-else>
                                <div class="simple-shop-text" :style="{visibility: coupon.cashRedemptionKiosk.storeCode !== 686 ? 'visible' : 'hidden'}">{{ coupon.cashRedemptionKiosk.address }}</div>
                                <div class="simple-shop-text" :style="{visibility: coupon.cashRedemptionKiosk.storeCode !== 686 ? 'visible' : 'hidden'}">{{ coupon.cashRedemptionKiosk.region }}</div>
                            </div>
                        </div>
                        <div v-if="!coupon.cashRedemptionKiosk && coupon.cashRedemptionDate" class="coupon-redeemed">
                            <img src="@/assets/shop.png" alt="" class="mt-n5">
                            <p v-if="!coupon.cashRedemptionKiosk" class="header-shop-text">{{ $t('webCoupons.franchise') }}</p>
                        </div>
                    </div>
                    <div v-if="coupon.cashRedemptionDate" class="button-cash-redemption">
                        {{ $t('webCoupons.cashRedemption') }}
                        {{ dateTimeUtils.dateFormatter(coupon.cashRedemptionDate) }}
                    </div>
                    <div v-if="coupon.redemptionDate && !coupon.cashRedemptionDate" class="button-activated">
                        {{ $t('webCoupons.activatedButton') }}
                        {{ dateTimeUtils.dateFormatter(coupon.redemptionDate) }}
                    </div>
                    <b-button v-if="!coupon.redemptionDate && !coupon.cashRedemptionDate" class="activate-button" :disabled="coupon.redemptionWay" @click="selectCoupon(coupon)">
                        {{ $t('webCoupons.pressButton') }}<img class="" src="@/assets/arrow.png" alt=" " draggable="false">
                    </b-button>
                </div>
            </div>
        </div>
        <div v-if="!promoLinks.length" class="bottom-container-no-promolinks">
            <div class="disclaimer-no-promolinks mb-4">
                <div>{{ $t('webCoupons.disclaimer[0]') }}</div>
                <div>{{ $t('webCoupons.disclaimer[1]') }}</div>
                <div class="small-text">{{ $t('webCoupons.disclaimer[2]') }}</div>
                <div class="small-text">{{ $t('webCoupons.disclaimer[3]') }}</div>
                <div class="small-text">{{ $t('webCoupons.disclaimer[4]') }}</div>
            </div>
        </div>
        <div v-else class="bottom-container">
            <div
                class="promo-link"
            >
                <div
                    v-for="(promoLink,index) in promoLinks"
                    :key="index"
                >
                    <promo-link :promo-data="promoLink" />
                </div>
            </div>
            <div class="disclaimer-container mb-4">
                <div>{{ $t('webCoupons.disclaimer[0]') }}</div>
                <div>{{ $t('webCoupons.disclaimer[1]') }}</div>
                <div class="small-text">{{ $t('webCoupons.disclaimer[2]') }}</div>
                <div class="small-text">{{ $t('webCoupons.disclaimer[3]') }}</div>
                <div class="small-text">{{ $t('webCoupons.disclaimer[4]') }}</div>
            </div>
        </div>
        <a v-if="config.env === 'development'" href="https://delhaizeapp.page.link/loyalty-cards" class="btn btn-primary">Test</a>
    </div>
</template>

<script>
    import pageViewsService from '@/services/page-views-service'
    import offerService from '@/services/offer-service'
    import dateTimeUtils from '@/utils/date-time-utils'
    import promoLinkService from '@/services/promo-link-service'
    import PromoLink from './PromoLink'
    import config from '@/mixins/config'

    export default {
        components: {
            PromoLink
        },
        mixins: [config],
        props: {
            coupons: { type: Array, default: () => [] },
            offer: { type: Object, default: null }
        },
        data() {
            return {
                componentKey: 0,
                dateTimeUtils: dateTimeUtils,
                activatedCoupons: 0,
                promoLinks: []
            }
        },
        computed: {
            isAllEnabled() {
                return this.activatedCoupons === this.offer.coupons.length
            }
        },
        created() {
            pageViewsService.submitPageView('AB OFFERS COUPONS', this.$route.path, this.$route.params)
            this.activeCoupons()
            this.fetchPromo()
        },
        methods: {
            selectCoupon(coupon) {
                if (coupon.redemptionWay) {
                    return
                }
                this.activateCoupons([coupon])
            },
            async activateCoupons(coupons) {
                const promoIds = coupons.map(coupon => coupon.promoId)
                await offerService.redeemCoupons(this.offer.id, promoIds).then(res => {
                    coupons.forEach(coupon => {
                        if (!coupon.isFlipped) {
                            this.activatedCoupons++
                            coupon.isFlipped = true
                            coupon.redemptionDate = dateTimeUtils.getCurrentDate()
                            if (this.activatedCoupons === 1) {
                                window.fbq('trackCustom', 'CouponActivators')
                            }
                            window.fbq('trackCustom', 'CouponActivations')
                        }
                    })
                }
                )
            },
            activateAllCoupons() {
                const couponsToActivate = this.offer.coupons.map(coupon => {
                    if (!coupon.redemptionWay) {
                        return coupon
                    }
                }).filter(coupon => coupon)

                this.activateCoupons(couponsToActivate)
            },
            pageChange(pageNumber) {
                if (isNaN(pageNumber)) {
                    pageNumber = 0
                }
                this.slideIndex = pageNumber
            },
            isLoadedOnCard(coupon) {
                return coupon.redemptionWay
            },
            activeCoupons() {
                let printedCoupons = 0
                this.offer.coupons.forEach(coupon => {
                    if (this.isLoadedOnCard(coupon)) {
                        printedCoupons++
                    }
                })
                this.activatedCoupons = printedCoupons
            },
            fetchPromo() {
                promoLinkService.fetchPromoLink(this.offer.id).then((response) => {
                    response.content.forEach((promoLink) => {
                        if (promoLink.id) {
                            this.promoLinks.push(promoLink)
                        }
                    })
                })
            }
        }
    }
</script>

<style scoped>

.coupon-container img {
    max-width: 100%;
}

.background {
    background: #fff;
}

.list-container {
    display: flex;
    font-size: 20px;
    font-family: sans-serif;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
}

.coupon-selection-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.coupons-number {
    font-size: 20px;
    font-weight: 700;
    background: #fff;
    color: #00aeef;
    border: 2px solid #00aeef;
    height: 45px;
    width: 100%;
    max-width: 350px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.select-all-btn {
    width: 100%;
    max-width: 350px;
    height: 45px;
    background: #004bd1;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
}

.select-all-btn.disabled {
    background: #d3d3d3;
    color: #fff;
    pointer-events: none;
}

.select-all-btn:not(.disabled):hover {
    background: hsl(218, 100%, 55%);
    color: #fff;
}

.select-all-btn:not(.disabled):active {
    transform: scale(0.95);
}

.header-container {
    background: #00aeef;
    color: #fff;
    width: 100%;
    padding: 1rem 0;
    margin-top: 1rem;
}

.header-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.header {
    line-height: 1.1;
    font-family: 'CFA-Bold';
    font-size: 26px;
}

.valid-until {
    font-family: 'CFA-Light';
    font-size: 18px;
}

.instruction-outer-container {
    display: flex;
    gap: 0.5rem;
    width: 95%;
    justify-content: space-between;
}

.instruction-container {
    background: rgba(42, 171, 226, 0.1);
    border-radius: 8px;
    color: #102e9e;
    font-family: 'CFA-Light';
    min-height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    flex: 1;
    width: 100%;
    padding: 0.5rem;
}

.step-number {
    font-size: 1.75rem;
}

.bold-instruction {
    font-size: 13px;
    font-family: 'CFA-Bold';
    line-height: 20px;
}

.coupons-container {
    display: grid;
    padding: 0 6% 5%;
    width: 100%;
    gap: 1.5%;
    grid-template-columns: repeat(4, 23.87%);
    box-sizing: border-box;
}

.coupon-container {
    background: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    width: 100%;
}

.activate-button {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'CFA-Light';
    font-weight: 700;
    margin: 0.3rem 0 0.5rem;
    padding: 0.6rem 1rem;
    background: #004bd1;
    border-radius: 5.51778px;
    border: none;
}

.coupon {
    cursor: pointer;
}

.image-redeemed {
    position: relative;
}

.opacity-changer {
    opacity: 0.15;
}

.coupon-redeemed {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #253e92;
    background-color: rgba(42, 171, 226, 0.4);
}

.header-shop-text {
    font-size: 18px;
    font-family: 'CFA-Bold';
}

.simple-shop-text {
    font-size: 15px;
    font-family: 'CFA-Bold';
}

.button-cash-redemption {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'CFA-Light';
    font-weight: 700;
    margin: 0.3rem 0 0.5rem;
    padding: 0.6rem 1rem;
    background: #efefef;
    border-radius: 8.13043px;
    border: none;
    color: #9c9c9c;
}

.button-activated {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'CFA-Light';
    font-weight: 700;
    margin: 0.3rem 0 0.5rem;
    padding: 0.6rem 1rem;
    background: #e7f6fb;
    border-radius: 8.13043px;
    border: none;
    color: #2aabe2;
}

.coupon.activated {
    opacity: 0.6;
    cursor: default;
}

.bottom-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 6%;
}

.bottom-container-no-promolinks {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 6%;
}

.promo-link {
    display: flex;
    align-items: center;
    padding-right: 3%;
}

.disclaimer-container {
    color: #253e92;
    text-align: left;
    font-size: 20px;
    border-left: 1px solid #253e92;
    padding-left: 3%;
}

.disclaimer-no-promolinks {
    color: #253e92;
    text-align: left;
    font-size: 20px;
    border-top: 1px solid #253e92;
    width: 100%;
    padding-top: 15px;
}

.small-text {
    font-size: 14px;
}

@media screen and (max-width: 1000px) {
    .coupons-container {
        row-gap: 0;
        column-gap: 2%;
        grid-template-columns: repeat(2, 49%);
    }

    .bottom-container {
        padding: 0 6%;
    }

    .header-container {
        width: 100%;
    }

    .promo-link {
        flex-direction: column;
    }
}

@media screen and (max-width: 767px) {
    .header {
        font-size: 20px;
    }

    .valid-until {
        font-size: 14px;
    }

    .coupons-container {
        grid-template-columns: repeat(1, 100%);
        padding: 0 9%;
    }

    .bottom-container {
        grid-template-columns: 1fr;
        padding: 0 6%;
    }

    .disclaimer-container {
        font-size: 16px;
        border-top: 1px solid #253e92;
        border-left: none;
        padding: 3% 0 0;
        text-align: left;
    }

    .promo-link {
        padding: 3% 0 0;
    }

    .small-text {
        font-size: 11px;
    }
}

@media screen and (max-width: 577px) {
    .coupons-container {
        padding: 0 8%;
    }

    .disclaimer-container {
        font-size: 14px;
    }

    .small-text {
        font-size: 11px;
    }
}

@media screen and (max-width: 480px) {
    .coupons-container {
        padding: 0 8%;
    }

    .header {
        font-size: 16px;
    }

    .valid-until {
        font-size: 12px;
    }
}

@media screen and (min-width: 768px) {
    .coupon-selection-section {
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        width: 95%;
        margin: 0 auto 1rem;
    }

    .coupons-number {
        height: 53px;
        max-width: 100%;
        font-size: 24px;
    }

    .select-all-btn {
        max-width: 100%;
        height: 53px;
        font-size: 24px;
    }

    .instruction-container {
        min-height: 75px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 0.25rem;
    }

    .step-number {
        font-family: 'CFA-Light';
        font-size: 1.875rem;
    }

    .bold-instruction {
        font-size: 1.3125rem;
        font-family: 'CFA-Bold';
        transform: translateY(2px);
    }
}
</style>
