import restAPI from '@/utils/rest-api'

export default {
    fetchOfferByIdWithCustomerData(offerId) {
        return restAPI.get(`/offers/${offerId}/with-customer-data`)
    },
    fetchDefaultOffer() {
        return restAPI.get('/offers/default')
    },
    redeemCoupons(offerId, couponPromoIds) {
        return restAPI.post(`/offers/${offerId}/redeem-on-web`, { couponPromoIds })
    },
    getAugmentedDataCoupons(offer) {
        const coupons = []
        offer.coupons = offer.coupons.sort(function(a, b) {
            return a.position - b.position
        })

        for (let coupon of offer.coupons) {
            coupon.isFlipped = coupon.redemptionWay === 'flip'
            coupon.isKioskRedeemed = coupon.redemptionWay === 'scan' || coupon.redemptionWay === 'pad'

            const host = process.env.VUE_APP_ASSETS_HOST
            const couponExtraProps = {
                colorPath: `${host}/${coupon.digitalImage.relativePath}/${coupon.digitalImage.fileName}`
            }

            coupon = { ...couponExtraProps, ...coupon }
            coupons.push(coupon)
        }
        return coupons
    },
    webOfferViewed(offerId, channel) {
        return restAPI.post(`/offers/${offerId}/web-offer-viewed${channel}`)
    }
}
