<template>
    <div class="background-image">
        <top-bar />
        <div class="main-container">
            <div class="left-container">
                <button :class="[!historyOn ? 'button-container' : 'active-button' ]" @click="showNotPlayedGames()">
                    <div class="w-100">
                        <div>{{ $t('gamesHistory.availableGames')[0] }}</div>
                        <div class="bold-text text-width-responsive">{{ $t('gamesHistory.availableGames')[1] }}</div>
                    </div>
                    <div class="sum-points-text bold-text">{{ notPlayedGames.length }}</div>
                    <img v-if="!historyOn" src="@/assets/arrow1_resp.png" class="responsive-element-button mt-1" alt="">
                    <img v-else src="@/assets/arrow2_resp.png" class="responsive-element-button mt-1" alt="">
                </button>
                <button ref="selectedok" :class="[historyOn ? 'button-container' : 'active-button']" @click="showHistory()">
                    <div class="w-100">
                        <div>{{ $t('gamesHistory.completedGames')[0] }}</div>
                        <div class="bold-text text-width-responsive">{{ $t('gamesHistory.completedGames')[1] }}</div>
                    </div>
                    <div class="sum-points-text bold-text">{{ gameHistory.length }}</div>
                    <img v-if="historyOn" src="@/assets/arrow1_resp.png" class="responsive-element-button mt-1" alt="">
                    <img v-else src="@/assets/arrow2_resp.png" class="responsive-element-button mt-1" alt="">
                </button>
            </div>
            <div class="wrapper">
                <div v-if="notPlayedGames.length == 0 && !historyOn" class="no-games-yet">
                    <div class="px-0">{{ $t('gamesHistory.noGamesYet[0]') }}</div>
                </div>
                <div
                    v-for="(game, index) in games"
                    :key="game.id"
                    class="border-game"
                >
                    <div class="game-row-container">
                        <div class="index-number hidden-element">{{ index + 1 }}</div>
                        <img v-if="game.thumbnailImage" class="game-image" :src="config.assetsHost + '/' + game.thumbnailImage.relativePath + '/' + game.thumbnailImage.fileName">
                        <div v-else class="image-placeholder" />
                        <div class="first-half-row">
                            <div class="game-title">{{ game.title }}</div>
                            <div class="hr hidden-element" />
                            <div v-if="historyOn" class="location-container">
                                <img v-if="game.playedOnKioskName" src="@/assets/kiosk_icon.png" alt="" draggable="false" class="mx-4 hidden-element">
                                <img v-else src="@/assets/web_icon.png" alt="" draggable="false" class="mx-4 hidden-element">
                                <div>
                                    <div class="date-text">{{ dateFormatter(game.playedOnDate) }}</div>
                                    <div v-if="game.playedOnKioskName" class="location-text">{{ game.playedOnKioskName }}</div>
                                    <div v-else class="location-text">{{ $t('gamesHistory.webPoint') }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-if="game.played" class="hr" />
                        <div v-if="game.pointsEarned > 0" class="inside-points-star">
                            <div class="point-container">
                                <span class="mr-2">+{{ game.pointsEarned }}</span>
                                <span class="star-text mr-2 hidden-element">{{ $t('gamesHistory.points') }}</span>
                            </div>
                            <div class="d-flex">
                                <span class="responsive-element star-text mr-2">{{ $t('gamesHistory.points') }}</span>
                                <img src="@/assets/game_star.png" alt="" draggable="false" class="star-icon mx-2 hidden-element">
                            </div>
                        </div>
                        <div v-else class="inside-points-star">
                            <div class="point-container">
                                <span class="mr-2">+{{ game.pointsList[ game.pointsList.length -1 ] }}</span>
                                <span class="star-text mr-2 hidden-element">{{ $t('gamesHistory.points') }}</span>
                            </div>
                            <div class="d-flex">
                                <span class="responsive-element star-text mr-2">{{ $t('gamesHistory.points') }}</span>
                                <img src="@/assets/game_star.png" alt="" draggable="false" class="star-icon mx-2 hidden-element">
                            </div>
                        </div>
                        <button v-if="game.played" class="play-again-btn" @click="clickGame(game)">
                            <div class="hidden-element">
                                <div>{{ $t('gamesHistory.playAgain')[0] }}</div>
                                <div>{{ $t('gamesHistory.playAgain')[1] }}</div>
                            </div>
                            <img src="@/assets/play-again-resp.png" alt="" class="responsive-element">
                            <img src="@/assets/play_again_icon.png" alt="" class="hidden-element">
                        </button>
                        <button v-else class="play-first-time-btn" @click="clickGame(game)">
                            <div class="hidden-element">
                                <div>{{ $t('gamesHistory.play')[0] }}</div>
                                <div>{{ $t('gamesHistory.play')[1] }}</div>
                            </div>
                            <img src="@/assets/play-resp.png" alt="" class="responsive-element">
                            <img src="@/assets/play-resp.png" alt="" class="hidden-element">
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-shadow" />
    </div>
</template>

<script>
    import TopBar from '@/components/TopBar'
    import config from '@/mixins/config'
    import gameService from '@/services/game-service'
    import moment from 'moment-timezone'
    export default {
        name: 'GamesHistoryRoute',
        components: {
            TopBar
        },
        mixins: [config],
        data() {
            return {
                notPlayedGames: [],
                historyOn: true
            }
        },
        computed: {
            gameHistory() {
                return this.$store.getters.getGameHistory
            },
            totalPoints() {
                let points = 0
                for (const game of this.gameHistory) {
                    points += game.pointsEarned
                }
                return points
            },
            profit() {
                return (this.totalPoints * 6) / 200
            },
            firstHistoryRow() {
                if (this.gameHistory && this.gameHistory[0]) {
                    return this.gameHistory[0]
                }
                return null
            },
            games() {
                if (this.historyOn) {
                    return this.gameHistory
                } else {
                    return this.notPlayedGames
                }
            }
        },
        created() {
            this.getNotPlayedGames()
            this.historyOn = false
        },
        mounted() {
            this.$refs.selectedok.focus()
        },
        methods: {
            dateFormatter: (value, row, index) => {
                return value
                    ? moment(value).tz('Europe/Athens').format('DD/MM/YYYY HH:mm') : value
            },
            getNotPlayedGames() {
                this.historyOn = false
                gameService.fetchGamePool().then((resp) => {
                    this.notPlayedGames = []
                    for (const activeGame of resp.content) {
                        if (!activeGame.played) {
                            this.notPlayedGames.push(activeGame)
                        }
                    }
                })
            },
            showHistory() {
                this.historyOn = true
            },
            clickGame(game) {
                if (!game.played) {
                    this.$router.push(`/games/${game.id}`)
                } else {
                    this.gameWithNoPoints = game
                    this.$store.dispatch('showPlayAgainModal', this.gameWithNoPoints)
                }
            },
            showNotPlayedGames() {
                this.historyOn = false
            }
        }
    }
</script>

<style scoped lang="scss">
    .bottom-shadow {
        height: 90px;
        margin-bottom: 120px;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    }

    .first-half-row {
        display: flex;
        align-items: center;
    }

    .main-container {
        display: flex;
        justify-content: flex-start;
        margin-top: 40px;
    }

    .game-row-container {
        display: flex;
        align-items: center;
    }

    .hr {
        border-right: 1px solid #2aabe2;
        widows: 0;
        height: 70px;
    }

    .location-container {
        width: 310px;
        display: flex;
        align-items: center;
    }

    .point-container {
        margin-left: 25px;
        font-size: 38px;
        line-height: 38px;
        margin-top: 25px;
        height: 65px;
        text-align: right;
        width: 80px;
        padding-right: 0;
        display: flex;
        flex-direction: column;
    }

    .left-container {
        margin: 29px 50px 0 70px;
        display: flex;
        flex-direction: column;
    }

    .background-image {
        background: url('~@/assets/games_home_background.png');
        background-repeat: repeat;
        height: 100%;
        position: fixed;
        width: 100%;
        overflow: scroll;
        background-size: cover;
    }

    .custom-margin {
        margin-top: 80px;
    }

    .button-container {
        padding: 20px;
        color: #fff;
        font-weight: 700;
        font-family: 'CFA-light';
        text-align: left;
        font-size: clamp(0.9375rem, 1vw + 1rem, 1.375rem);
        background: #286aff;
        width: 280px;
        height: 170px;
        outline: none;
        border: 2px solid #286aff;
        border-radius: 6px;
        margin-bottom: 35px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    .active-button {
        padding: 20px;
        color: #2aabe2;
        font-weight: 700;
        font-family: 'CFA-light';
        text-align: left;
        font-size: clamp(0.9375rem, 1vw + 1rem, 1.375rem);
        background: #ffff;
        width: 280px;
        height: 170px;
        outline: none;
        border: 2px solid #fff;
        border-radius: 6px;
        margin-bottom: 35px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    .responsive-element {
        display: none;
    }

    .responsive-element-button {
        display: none;
        margin-right: -15px;
    }

    .sum-points-text {
        width: 50px;
        font-size: 60px;
    }

    .benefit-container {
        padding: 20px;
        width: 280px;
        height: 170px;
        color: #fff;
        font-weight: 700;
        font-family: 'CFA-light';
        text-align: left;
        font-size: 22px;
        background: #eb2127;
        border-radius: 6px;
        margin-bottom: 35px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    .vertical-align {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    .bold-text {
        font-family: 'CFA-bold';
    }

    .benefit-points {
        font-size: clamp(25px, 4vmin, 35px);
        color: #000;
        font-family: 'CFA-bold';
    }

    .profit-points {
        margin-top: -4%;
        margin-right: 2%;
        font-size: clamp(13px, 1.5vmin, 1.125rem);
        color: gold;
        font-family: 'CFA-light';
        font-weight: 700;
    }

    .play-again-btn {
        border: none;
        display: flex;
        justify-content: space-evenly;
        column-gap: 10px;
        align-items: center;
        margin-left: 104px;
        margin-right: 10px;
        border-radius: 12px;
        line-height: 22px;
        font-size: 20px;
        background: #253e92;
        font-family: 'CFA-Bold';
        width: 185px;
        height: 60px;
        color: #fff;
    }

    .play-first-time-btn {
        border: none;
        display: flex;
        justify-content: space-evenly;
        column-gap: 10px;
        align-items: center;
        margin-left: 415px;
        margin-right: 10px;
        border-radius: 12px;
        line-height: 23px;
        font-size: 20px;
        background: #253e92;
        font-family: 'CFA-Bold';
        width: 185px;
        height: 60px;
        color: #fff;
    }

    .play-again-btn:active {
        outline: none;
        border: none;
    }

    .play-again-btn:focus {
        outline: none;
        border: none;
    }

    .border-game {
        height: 90px;
        margin: 30px;
        background: #fff;
        border: 2px solid #2aabe2;
        border-radius: 25px;
        display: flex;
        align-items: center;
        padding: 5px;
    }

    .inside-points-star {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'CFA-bold';
        color: #253e92;
        font-size: 32px;
    }

    .game-image {
        max-height: 70px;
        max-width: 70px;
        min-width: 50px;
        width: 100%;
        height: auto;
        padding: 2px;
        border: 1px solid #fff;
        border-radius: 12px;
        margin-right: 30px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
    }

    .image-placeholder {
        height: 84px;
        width: 84px;
        padding: 2px;
        border: 1px solid #fff;
        box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background-color: #565858;
    }

    .game-title {
        color: #253e92;
        font-family: 'CFA-bold';
        font-size: 24px;
        width: 470px;
        text-align: left;
    }

    .index-number {
        margin-left: 18px;
        margin-right: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        border-radius: 50px;
        font-weight: 600;
        font-family: 'CFA-Light';
        text-align: center;
        font-size: 20px;
        background: #2aabe2;
        color: #fff;
    }

    .date-text {
        font-size: 16px;
        color: #253e92;
        font-family: 'CFA-light';
        font-weight: 600;
    }

    .star-icon {
        margin-bottom: 0.5rem;
    }

    .wrapper {
        padding-bottom: 11rem;
        width: 100%;
    }

    .location-text {
        font-size: 15px;
        font-family: 'CFA-bold';
        color: #253e92;
    }

    .no-games-yet {
        font-size: 30px;
        font-family: 'CFA-bold', 'sans-serif';
        color: #0d5198;
        text-align: center;
        margin-right: 25%;
        margin-top: 5%;
        margin-left: 25%;
    }

    .star-text {
        font-size: 12px;
        line-height: 12px;
        color: #2aabe2;
        font-family: 'CFA-light';
        font-weight: 600;
    }

    @media screen and (max-width: 1600px) {
        .bottom-shadow {
            margin-bottom: 70px;
            height: 60px;
        }

        .no-games-yet {
            padding: 0 15px;
            margin: 0 auto;
        }

        .hidden-element {
            display: none;
        }

        .play-again-btn {
            width: auto;
            max-width: 50px;
            margin-left: 0;
            max-height: 50px;
        }

        .play-first-time-btn {
            width: auto;
            max-width: 50px;
            margin-left: 0;
            max-height: 50px;
        }

        .point-container {
            font-size: 31px;
            margin-left: 4px;
            line-height: 29px;
            margin-top: 0;
            height: unset;
            display: flex;
            flex-direction: column;
        }

        .responsive-element {
            display: inline;
        }

        .responsive-element-button {
            display: inline;
        }

        .sum-points-text {
            text-align: right;
            font-size: 35px;
        }

        .wrapper {
            margin-top: 33px;
            margin-bottom: 110px;
        }

        .date-text {
            font-size: 11px;
        }

        .location-container {
            width: 150px;
        }

        .background-image {
            background: url('~@/assets/pattern-bg.jpg');
            background-repeat: repeat;
            height: 100%;
            position: fixed;
            width: 100%;
            overflow: scroll;
        }

        .location-text {
            font-size: 11px;
        }

        .left-container {
            margin: 0 auto;
        }

        .button-container {
            display: flex;
            line-height: 20px;
            padding: 10px;
            margin-bottom: 20px;
            width: 360px;
            height: 60px;
            align-items: center;
        }

        .active-button {
            display: flex;
            padding: 10px;
            line-height: 20px;
            margin-bottom: 20px;
            width: 360px;
            height: 60px;
            align-items: center;
        }

        .border-game {
            width: 369px;
            height: 90px;
            border-radius: 12px;
            margin: 13px auto;
        }

        .star-icon {
            margin-bottom: 0;
            width: 15px;
            height: 15px;
        }

        .star-text {
            font-size: 12px;
            line-height: unset;
            color: #2aabe2;
            font-family: 'CFA-light';
            font-weight: 600;
            margin-left: 12px;
        }

        .game-image {
            margin-right: 4px;
        }

        .inside-points-star {
            flex-direction: column;
            align-items: end;
        }

        .game-title {
            font-size: 13px;
            width: auto;
        }

        .first-half-row {
            flex-direction: column;
            align-items: flex-start;
            width: 155px;
            padding: 0 3px;
        }

        .main-container {
            flex-direction: column;
        }
    }
</style>
